import { useState } from "react";
import { db, storage } from "../../../config/Config";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { updateProduct } from "../../../redux/addToCart/CartSlice";
import { useDispatch } from "react-redux";

const useSubmitProduct = (variants, variantsList) => {
  const [name, SetName] = useState("");
  const [category, SetCategory] = useState("");
  const [currency, SetCurrency] = useState("UAH");
  const [description, SetDescription] = useState("");
  const [details, SetDetails] = useState([
    { title: null, data: null, id: Math.random().toString(36).substring(2) },
  ]);
  const [images, SetImages] = useState([]);
  const [error, SetError] = useState(null);
  const [isLoading, SetLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewDetails = () => {
    SetDetails((prev) => [
      ...prev,
      { title: null, data: null, id: Math.random().toString(36).substring(2) },
    ]);
  };

  const deleteDetail = (id) => {
    SetDetails((oldValues) => {
      return oldValues.filter((detail) => detail.id !== id);
    });
  };

  const handleTitleChange = (event, id) => {
    const updatedDetails = details.map((detail) => {
      if (detail.id === id) {
        return { ...detail, title: event.target.value };
      }
      return detail;
    });
    SetDetails(updatedDetails);
  };

  const handleDataChange = (event, id) => {
    const updatedDetails = details.map((detail) => {
      if (detail.id === id) {
        return { ...detail, data: event.target.value };
      }
      return detail;
    });
    SetDetails(updatedDetails);
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    if (!images) {
      alert("Please choose a file first!");
      return;
    }

    try {
      SetLoading(true);
      const uploadPromises = [];
      const downloadUrls = [];

      images.forEach((file) => {
        const storageRef = ref(
          storage,
          `products_images/${Date.now()}-${file.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, file);
        const downloadUrlPromise = new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            () => {},
            (error) => {
              console.error("Error uploading image:", error);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((downloadURL) => {
                  resolve(downloadURL);
                })
                .catch((error) => {
                  console.error("Error getting download URL:", error);
                  reject(error);
                });
            }
          );
        });

        uploadPromises.push(uploadTask);
        downloadUrls.push(downloadUrlPromise);
      });

      await Promise.all(uploadPromises); // Wait for all the images to finish uploading

      const urls = await Promise.all(downloadUrls); // Wait for all the download URLs to be retrieved

      await addDoc(collection(db, "Products"), {
        id: Math.random().toString(36).substring(2).toString(),
        name,
        category,
        variants,
        variantsList,
        currency,
        details: details.length > 0 && details[0].data ? details : null,
        description,
        product_images: urls,
      });

      // Reset form fields
      SetName("");
      SetCategory("");
      SetCurrency("UAH");
      SetDescription("");
      SetLoading(false);
      dispatch(updateProduct(true));
      navigate("/");
    } catch (error) {
      SetLoading(false);
      console.error("Error submitting form:", error);
    }
  };

  const types = ["image/png", "image/jpeg"];

  const imgHandler = (e, SetImages, SetError) => {
    let selectedImages = e.target.files;

    for (let i = 0; i < e.target.files.length; i++) {
      if (selectedImages.length > 0 && types.includes(selectedImages[i].type)) {
        const newImage = e.target.files[i];
        newImage["id"] = Math.random().toString(36).substring(2);
        SetImages((prev) => [...prev, newImage]);
        SetError(null);
      } else {
        SetImages(null);
        SetError("Please select a valid image type png or jpeg");
      }
    }
  };

  return [
    name,
    SetName,
    currency,
    SetCurrency,
    description,
    SetDescription,
    details,
    SetImages,
    error,
    SetError,
    onSubmitForm,
    imgHandler,
    isLoading,
    addNewDetails,
    deleteDetail,
    handleDataChange,
    handleTitleChange,
    category,
    SetCategory,
  ];
};

export default useSubmitProduct;
