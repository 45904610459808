import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import Skeleton from "../../common/Skeleton";
import useImageLoad from "../hooks/useImageLoad";

const ProductCard = ({ product, documentId }) => {
  const navigate = useNavigate();
  const [minPrice, setminPrice] = useState("");
  const [showSecondImage, setShowSecondImage] = useState(false);
  const [hoverCard, setHoverCard] = useState(false);
  const [isLoading, handleImageLoad] = useImageLoad();
  const userInfo = useSelector((state) => state.loginSlice.userInfo);

  useEffect(() => {
    if (product) {
      const minValue = product.variants.reduce(
        (min, obj) => (parseInt(obj.price) < parseInt(min) ? obj.price : min),
        product.variants[0].price
      );
      const objectsWithMinPrice = product.variants.filter(
        (obj) => obj.price === minValue
      );
      setminPrice(objectsWithMinPrice[0]);
    }
  }, [product]);

  const onRedirect = (link) => {
    navigate(link, {
      state: { product: product, docId: documentId },
    });
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex flex-col items-start justify-start cursor-pointer mt-5 relative sm:max-w-[270px] max-w-[320px]">
      {userInfo && (
        <div
          className="absolute w-[32px] h-[32px] rounded-full bg-gray-500 right-2 top-2 flex justify-center items-center cursor-pointer"
          onClick={() => onRedirect("/edit_product")}
        >
          <MdEdit size={20} color={"#fff"} />
        </div>
      )}
      <div
        onClick={() => onRedirect(`/product/${product.id}`)}
        className="w-full"
        onMouseEnter={() => setHoverCard(true)}
        onMouseLeave={() => setHoverCard(false)}
      >
        {isLoading && <Skeleton />}
        <img
          className={`w-[320px] lg:w-[270px] sm:h-[270px] h-[320px] bg-gray-100 object-cover transition-all`}
          src={
            showSecondImage && product.product_images.length > 1
              ? product.product_images[1]
              : product.product_images[0]
          }
          style={{ display: isLoading ? "none" : "block" }}
          onLoad={handleImageLoad}
          onMouseEnter={() => setShowSecondImage(true)}
          onMouseLeave={() => setShowSecondImage(false)}
          alt="product_image"
        />
        <p
          className={`${
            hoverCard ? "underline" : ""
          } mb-2 mt-5 text-sm font-light`}
        >
          {product.name}
        </p>
        <div className="font-light flex flex-row justify-start items-center">
          {minPrice.oldprice ? (
            <p className="line-through mr-2 text-gray-400 text-sm">
              {minPrice.oldprice + " " + product.currency}
            </p>
          ) : null}
          {product.variants.length > 1 && <p className="mr-2">ВІД</p>}
          {minPrice.price ? (
            <p
              className={`${
                minPrice.oldprice ? "text-red-500 font-bold text-lg" : ""
              } `}
            >
              {minPrice.price} {product.currency}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
