import React from "react";
import {
  Alert,
  Button,
  FileInput,
  Label,
  Select,
  Textarea,
  TextInput,
} from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { RiDeleteBin2Line } from "react-icons/ri";
import useSubmitProduct from "./hooks/useSubmitProduct";
import LoadingBoundary from "../common/LoadingBoundary";
import VariantsForm from "./Variants/VariantsForm";
import useVariants from "./hooks/useVariants";

const AddProductForm = () => {
  const [
    addNewVariants,
    deleteVariants,
    handleNameVariantsChange,
    handleValueVariantsChange,
    variantsList,
    addNewOption,
    deleteOption,
    saved,
    onSaveVariants,
    variants,
    handleChangePrice,
    handleChangeQuantity,
    handleChangeOldPrice,
  ] = useVariants();

  const [
    name,
    SetName,
    currency,
    SetCurrency,
    description,
    SetDescription,
    details,
    SetImages,
    error,
    SetError,
    onSubmitForm,
    imgHandler,
    isLoading,
    addNewDetails,
    deleteDetail,
    handleDataChange,
    handleTitleChange,
    category,
    SetCategory,
  ] = useSubmitProduct(variants, variantsList);

  return (
    <LoadingBoundary isLoading={isLoading}>
      <form
        className="flex flex-col gap-4 w-[50%] min-w-[350px] max-w-[500px]"
        onSubmit={onSubmitForm}
      >
        {error && (
          <Alert
            color="failure"
            icon={HiInformationCircle}
            onDismiss={() => SetError(null)}
          >
            <span>
              <span className="font-medium">{error}</span>
            </span>
          </Alert>
        )}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="name" value="Name" />
          </div>
          <TextInput
            id="name"
            type="text"
            onChange={(e) => SetName(e.target.value)}
            value={name}
            required={true}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="category" value="Category" />
          </div>
          <TextInput
            id="category"
            type="text"
            onChange={(e) => SetCategory(e.target.value)}
            value={category}
            required={false}
          />
        </div>
        <VariantsForm
          variantsList={variantsList}
          addNewVariants={addNewVariants}
          deleteVariants={deleteVariants}
          handleNameVariantsChange={handleNameVariantsChange}
          handleValueVariantsChange={handleValueVariantsChange}
          addNewOption={addNewOption}
          deleteOption={deleteOption}
          saved={saved}
          onSaveVariants={onSaveVariants}
          variants={variants}
          handleChangePrice={handleChangePrice}
          handleChangeQuantity={handleChangeQuantity}
          handleChangeOldPrice={handleChangeOldPrice}
        />
        <div id="select">
          <div className="mb-2 block">
            <Label htmlFor="currency" value="Select Currency" />
          </div>
          <Select
            id="currency"
            required={true}
            onChange={(e) => SetCurrency(e.target.value)}
            value={currency}
          >
            <option>UAH</option>
            <option>USD</option>
          </Select>
        </div>
        <div id="textarea">
          <div className="mb-2 block">
            <Label htmlFor="description" value="Product Description" />
          </div>
          <Textarea
            id="description"
            placeholder="Describe Product"
            required={true}
            rows={4}
            onChange={(e) => SetDescription(e.target.value)}
            value={description}
          />
        </div>

        <div id="textarea">
          <div className="mb-2 block">
            <Label htmlFor="materials" value="Details (optinal)" />
          </div>
          {details.map((detail, id) => (
            <div
              key={id}
              className="flex flex-row items-center justify-start mb-4"
            >
              <div className="w-[90%]">
                <TextInput
                  id={"name" + id}
                  placeholder="Detail name"
                  value={detail.title ? detail.title : ""}
                  onChange={(event) => handleTitleChange(event, detail.id)}
                />
                <Textarea
                  id={"data" + id}
                  className="mt-2"
                  placeholder="Detail data"
                  rows={2}
                  value={detail.data ? detail.data : ""}
                  onChange={(event) => handleDataChange(event, detail.id)}
                />
              </div>
              <div className="ml-2">
                <RiDeleteBin2Line
                  className="cursor-pointer"
                  size={20}
                  onClick={() => deleteDetail(detail.id)}
                />
              </div>
            </div>
          ))}
          <p
            className="text-black border border-black mt-2 w-[100px] text-center cursor-pointer"
            onClick={addNewDetails}
          >
            Add detail
          </p>
        </div>

        <div id="fileUpload">
          <div className="mb-2 block">
            <Label htmlFor="file" value="Upload images" />
          </div>
          <FileInput
            id="multiple_files"
            type="file"
            multiple
            helperText="Product images"
            onChange={(e) => imgHandler(e, SetImages, SetError)}
          />
        </div>

        <Button
          className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none"
          type="submit"
          disabled={error ? true : false}
        >
          Add product
        </Button>
      </form>
    </LoadingBoundary>
  );
};

export default AddProductForm;
