import { createSlice } from "@reduxjs/toolkit";

export const LoginSlice = createSlice({
  name: "Login",
  initialState: {
    acessUser: "i.domyha@gmail.com",
    userInfo: null,
    links: [
      {
        id: 0,
        url: "https://www.tiktok.com/@deskwise?_t=8cuxjeXGveX",
        name: "tiktok",
      },
      {
        id: 1,
        url: "https://instagram.com/deskwise.shop?igshid=MjEwN2IyYWYwYw==",
        name: "instagram",
      },
      {
        id: 2,
        url: "https://t.me/I_Domaratskyi",
        name: "telegram",
      },
    ],
    min_order_value: 1,
  },
  reducers: {
    saveUser: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
});

export const { saveUser } = LoginSlice.actions;

export default LoginSlice.reducer;
