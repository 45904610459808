import React from "react";

const About = () => {
  return (
    <div className="py-20 px-10 flex justify-center items-center">
      <div className="flex flex-col max-w-[700px]">
        <h1 className="text-4xl">Про нас</h1>

        <p className="font-light mt-5">
          Ласкаво просимо до DESKWISE - українського інтернет-магазину килимків
          для миші. Ми спеціалізуємося на наданні найвищої якості килимків, які
          допомагають вам насолоджуватися комфортом та продуктивністю під час
          роботи з комп'ютером. DESKWISE - це місце, де ви знайдете широкий
          вибір килимків для миші, які відповідають найвищим стандартам якості
          та дизайну. Наша команда дбає про те, щоб кожен килимок був
          виготовлений з використанням найкращих матеріалів та зі слідами
          українського мистецтва.
          <br />
          <br />
          Ми розуміємо, що ваш робочий простір важливий для вашої
          продуктивності, тому ми пропонуємо килимки, які не тільки забезпечують
          гладку рухливість миші, але й відображають ваш стиль та особистість.
          Кожен килимок в DESKWISE - це результат поєднання мистецтва та
          функціональності.
          <br />
          <br />
          Ми пишаємося тим, що DESKWISE - це український магазин, який пропонує
          високоякісні килимки для миші з урахуванням потреб і смаків наших
          клієнтів. Ми прагнемо забезпечити найкращий сервіс та задоволення
          наших клієнтів кожного разу, коли вони звертаються до нас.
          <br />
          <br />
          Приходьте до DESKWISE і дайте своєму робочому простору той штрих краси
          та комфорту, який ви заслуговуєте. Наші килимки для миші стануть
          надійними помічниками у вашій роботі та принесуть вам задоволення від
          використання.
        </p>
      </div>
    </div>
  );
};

export default About;
