import { configureStore } from "@reduxjs/toolkit";
import addToCartReducer from "../redux/addToCart/CartSlice";
import loginSlice from "../redux/login/loginSlice";

export default configureStore({
  reducer: {
    addToCart: addToCartReducer,
    loginSlice: loginSlice,
  },
});
