import React from "react";

const CustomRadio = ({ setPaymentMethod, paymentMethod }) => {
  return (
    <>
      <ul className="grid w-full gap-6 md:grid-cols-2">
        <li>
          <label
            onClick={() => setPaymentMethod("Offline")}
            className={` h-[120px] ${
              paymentMethod === "Offline"
                ? "border-blue-600 text-blue-600 inline-flex items-center justify-between w-full p-5 bg-white border rounded-lg cursor-pointer hover:bg-gray-100"
                : "inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer hover:text-gray-600 hover:bg-gray-100"
            }`}
          >
            <div className="block">
              <div className="w-full text-lg font-semibold">
                Наложений платіж
              </div>
              <div className="w-full">Оплата при отриманні товару</div>
            </div>
          </label>
        </li>
        <li>
          <label
            onClick={() => setPaymentMethod("Online")}
            className={`h-[120px]  ${
              paymentMethod === "Online"
                ? "border-blue-600 text-blue-600 inline-flex items-center justify-between w-full p-5 bg-white border rounded-lg cursor-pointer hover:bg-gray-100"
                : "inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer hover:text-gray-600 hover:bg-gray-100"
            }`}
          >
            <div className="block">
              <div className="w-full text-lg font-semibold">
                Онлайн-оплата карткою,
                <br /> Google Pay або Apple Pay
              </div>
            </div>
          </label>
        </li>
      </ul>
    </>
  );
};

export default CustomRadio;
