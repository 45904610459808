import { Button } from "flowbite-react";
import React from "react";
import CustomRadio from "../../common/CustomRadio";

const Payment = ({
  paymentMethod,
  setPaymentMethod,
  isPayUrlLoading,
  payUrl,
  onClickPayment,
}) => {
  console.log(payUrl);
  return (
    <div className="min-h-default flex flex-col justify-center items-center my-20">
      <div className="flex flex-col items-center justify-center">
        <h3 className="text-3xl mb-5">Оплата</h3>
        <CustomRadio
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
        />
        <div className="mt-10">
          {paymentMethod === "Online" ? (
            <div
              className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none w-[150px] h-[45px] flex justify-center items-center"
              disabled={isPayUrlLoading || !payUrl ? true : false}
              onClick={onClickPayment}
            >
              <a
                href="#"
                className="w-full h-full flex justify-center items-center text-sm cursor-pointer"
              >
                Оплатити
              </a>
            </div>
          ) : (
            <Button
              className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none w-[150px] h-[45px]"
              onClick={onClickPayment}
            >
              Замовити
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
