import { Label, TextInput } from "flowbite-react";
import React from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { RiDeleteBin2Line } from "react-icons/ri";

const VariantsForm = ({
  variantsList,
  handleNameVariantsChange,
  handleValueVariantsChange,
  deleteVariants,
  addNewVariants,
  addNewOption,
  deleteOption,
  saved,
  onSaveVariants,
  variants,
  handleChangePrice,
  handleChangeOldPrice,
  handleChangeQuantity,
}) => {
  return (
    <div>
      <div className="mb-2 block">
        <Label htmlFor="variants" value="Variants" />
      </div>
      {variantsList.map((variant) => (
        <div
          key={variant.id}
          className="flex flex-col items-start justify-start mb-3 w-[100%]"
        >
          <div className="flex flex-row items-center w-full">
            <TextInput
              id="variants_name"
              type="text"
              disabled={saved}
              placeholder="Variant Name"
              required={true}
              className="w-[90%]"
              value={variant.title ? variant.title : ""}
              onChange={(event) => handleNameVariantsChange(event, variant.id)}
            />
            <div className="ml-2">
              <RiDeleteBin2Line
                className="cursor-pointer"
                size={20}
                hidden={saved}
                onClick={() => deleteVariants(variant.id)}
              />
            </div>
          </div>
          {variant.data.map((item, index) => (
            <div key={index} className="flex flex-row items-center mt-2 w-full">
              <BsArrowReturnRight size={25} />
              <TextInput
                id="variants_value"
                type="text"
                placeholder="Variant Value"
                className="w-[50%]"
                disabled={saved}
                required={true}
                value={item ? item : ""}
                onChange={(event) =>
                  handleValueVariantsChange(
                    variant.id,
                    index,
                    event.target.value
                  )
                }
              />
              <div className="ml-2">
                <RiDeleteBin2Line
                  className="cursor-pointer"
                  size={20}
                  hidden={saved}
                  onClick={() => deleteOption(variant.id, item)}
                />
              </div>
            </div>
          ))}
          <p
            className="text-black border border-black mt-2 ml-7 w-[100px] text-center cursor-pointer"
            onClick={() => addNewOption(variant.id)}
            hidden={saved}
          >
            Add option
          </p>
          <div className="w-full border-b rouded mt-2"></div>
        </div>
      ))}
      <div className="flex flex-row justify-between">
        <p
          className="text-black border border-black mt-2 w-[100px] text-center cursor-pointer"
          onClick={addNewVariants}
          hidden={saved}
        >
          Add variant
        </p>
        <p
          className="text-black border border-black mt-2 w-[100px] text-center cursor-pointer"
          onClick={() => onSaveVariants(!saved)}
        >
          {saved ? "Edit variants" : "Save variants"}
        </p>
      </div>
      {variants.length > 0 && (
        <div className="w-full">
          {variants.map((comb, i) => (
            <div key={i} className="flex flex-col items-start my-3">
              <p>{comb.variants.map((value) => value + "/")}</p>
              <div className="flex flex-col justify-between">
                <div className="flex flex-row justify-between">
                  <TextInput
                    id={"price" + i}
                    type="number"
                    placeholder="Product price"
                    className="w-[48%]"
                    value={comb.price}
                    required={true}
                    onChange={(e) => handleChangePrice(e, comb.id)}
                  />
                  <TextInput
                    id={"oldprice" + i}
                    type="number"
                    placeholder="Old price"
                    className="w-[48%]"
                    value={comb.oldprice}
                    onChange={(e) => handleChangeOldPrice(e, comb.id)}
                  />
                </div>
                <TextInput
                  id={"quantity" + i}
                  type="number"
                  placeholder="Product quantity"
                  className="mt-3"
                  required={true}
                  value={comb.quantity}
                  onChange={(e) => handleChangeQuantity(e, comb.id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VariantsForm;
