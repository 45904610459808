import React from "react";
import { Button, Label, TextInput, Tabs } from "flowbite-react";
import Poshta from "../NewPost/Poshta";
import UkrPoshta from "../UkrPoshta/UkrPoshta";

const Delivery = ({
  register,
  handleSubmit,
  onSubmit,
  setActiveTab,
  activeTab,
  newPost,
  handleCity,
  isCitySelected,
  cities,
  warehouses,
  documentBodyRef,
}) => {
  return (
    <div
      className="min-h-default flex flex-col justify-center items-center my-20"
      root={documentBodyRef.current}
    >
      <div className="flex flex-col items-center justify-center">
        <h3 className="text-3xl mb-5">Доставка</h3>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 w-[50%] min-w-[350px] max-w-[500px]"
        >
          <div>
            <div className="mb-2 block">
              <Label htmlFor="full_name" value="Повне Ім'я" />
            </div>
            <TextInput
              id="full_name"
              type="text"
              required={true}
              {...register("full_name", { required: true })}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="name" value="Телефон" />
            </div>
            <TextInput
              id="phone"
              type="tel"
              addon="+38"
              {...register("phone", {
                required: true,
                pattern: /^\d{10}$/,
              })}
            />
          </div>
          <Tabs.Group onActiveTabChange={(tab) => setActiveTab(tab)}>
            <Tabs.Item active title="Нова Пошта">
              {activeTab === 0 && (
                <Poshta
                  register={register}
                  onChangeCity={newPost}
                  handleCity={handleCity}
                  isCitySelected={isCitySelected}
                  cities={cities}
                  warehouses={warehouses}
                  seconLabel="Відділення Нової Пошти"
                />
              )}
            </Tabs.Item>
            <Tabs.Item title="Укрпошта">
              {activeTab === 1 && <UkrPoshta register={register} />}
            </Tabs.Item>
            <Tabs.Item title="Інше">
              <p className="font-light text-xs no-underline">
                З вами зв'яжеться менеджер для уточнення виду та дати доставки
                чи самовивозу
              </p>
            </Tabs.Item>
          </Tabs.Group>
          <Button
            className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none"
            type="submit"
          >
            Замовити
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Delivery;
