import React, { useState } from "react";
import { Table, Card, Button, Alert } from "flowbite-react";
import { useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/Config";
import LoadingBoundary from "../common/LoadingBoundary";
import PayStatus from "../common/PayStatus";
import { getPaymentStatus } from "../../api";
import { HiInformationCircle } from "react-icons/hi";

const Orders = () => {
  const [orders, setOrders] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [payStatus, setPayStatus] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);
        const querySnapshot = await getDocs(collection(db, "Orders"));
        const productsData = querySnapshot.docs.map((doc) => {
          return { data: doc.data(), docId: doc.id };
        });
        setOrders(productsData);
        setActiveOrder(productsData[0]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error getting orders:", error);
        setOrders([]);
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [payStatus]);

  const getFormatedDate = (timestamp) => {
    const date = timestamp.toDate();

    // Format the date as a string using the desired options
    const formattedDate = date.toLocaleString("uk-UA", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    return formattedDate;
  };

  return (
    <LoadingBoundary isLoading={isLoading}>
      <div className="min-h-default flex flex-col justify-start items-center py-20 px-5">
        {isError && (
          <Alert
            color="failure"
            icon={HiInformationCircle}
            onDismiss={() => setIsError(null)}
          >
            <span>
              <span className="font-medium">{isError}</span>
            </span>
          </Alert>
        )}
        <div className="w-full max-w-4xl">
          {activeOrder && (
            <Card className="w-full mb-10">
              <div className="flex justify-between">
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {activeOrder.data.name}
                </h5>
                <PayStatus activeOrder={activeOrder.data} />
              </div>
              <div className="flex justify-between flex-wrap">
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <p className="font-bold">Сума: </p>
                    <p> {activeOrder.data.generalSum} UAH</p>
                  </div>
                  <div className="flex flex-row">
                    <p className="font-bold">Спосіб Оплати: </p>
                    <p> {activeOrder.data.paymentMethod}</p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <p className="font-bold">Місто: </p>
                    <p> {activeOrder.data.city}</p>
                  </div>
                  <div className="flex flex-row">
                    <p className="font-bold">Дата: </p>
                    <p> {getFormatedDate(activeOrder.data.date)}</p>
                  </div>
                </div>
              </div>
              {activeOrder.data.products.map((item) => (
                <div key={item.id} className="flex flex-row items-center ">
                  <div>
                    <img src={item.image} alt={"pad"} className="w-[80px]" />
                  </div>
                  <p className="text-sm px-5">
                    {item.name}
                    <br />
                    Кількість: {item.quantity}
                  </p>
                </div>
              ))}
              {activeOrder.data.paymentMethod !== "AFTER_PAYMENT" && (
                <div className="w-full flex justify-center mt-5">
                  <Button
                    onClick={() =>
                      getPaymentStatus(
                        activeOrder.data.paymentMethod,
                        setIsError,
                        setIsLoading,
                        setPayStatus,
                        activeOrder.docId
                      )
                    }
                    className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none"
                  >
                    Оновити статус оплати
                  </Button>
                </div>
              )}
            </Card>
          )}
          <div className="max-h-[300px] overflow-y-auto">
            <Table striped>
              <Table.Head>
                <Table.HeadCell>Ім'я</Table.HeadCell>
                <Table.HeadCell>Спосіб Оплати</Table.HeadCell>
                <Table.HeadCell>Статус</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {orders &&
                  orders?.map((item) => (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer hover:bg-gray-100"
                      key={item.data.id}
                      onClick={() => setActiveOrder(item)}
                    >
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {item.data.name}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="flex flex-col justify-center items-start">
                          <p>{item.data.paymentMethod}</p>
                          <p>{item.data.generalSum}UAH</p>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <PayStatus activeOrder={item.data} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </LoadingBoundary>
  );
};

export default Orders;
