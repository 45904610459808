import React from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import useEditProduct from "./hooks/useEditProduct";
import { Button, Label, Select, Textarea, TextInput } from "flowbite-react";
import LoadingBoundary from "../common/LoadingBoundary";

const EditProduct = () => {
  let location = useLocation();

  const product = location.state?.product;
  const docId = location.state.docId;

  const { register, handleSubmit, setValue } = useForm();

  const [onSubmit, isLoading] = useEditProduct(product, setValue, docId);

  return (
    <div className="flex flex-col items-center justify-center py-10 min-h-[92vh]">
      <h1 className=" font-bold text-3xl mb-10">Edit product</h1>
      <LoadingBoundary isLoading={isLoading}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 w-[50%] min-w-[350px] max-w-[500px]"
        >
          <div>
            <div className="mb-2 block">
              <Label htmlFor="name" value="Name" />
            </div>
            <TextInput
              id="name"
              type="text"
              {...register("name")}
              required={true}
            />
          </div>
          <div className="w-full">
            {product &&
              product.variants.map((comb, i) => (
                <div key={i} className="flex flex-col items-start my-3">
                  <p>{comb.variants.map((value) => value + "/")}</p>
                  <div className="flex flex-col justify-between w-full">
                    <div className="flex flex-row justify-between">
                      <TextInput
                        id={"price" + i}
                        type="number"
                        placeholder="Product price"
                        className="w-[48%]"
                        required={true}
                        {...register(`price_${comb.id}`)}
                      />
                      <TextInput
                        id={"oldprice" + i}
                        type="number"
                        placeholder="Old price"
                        className="w-[48%]"
                        {...register(`oldprice_${comb.id}`)}
                      />
                    </div>
                    <TextInput
                      id={"quantity" + i}
                      type="number"
                      placeholder="Product quantity"
                      className="mt-3"
                      required={true}
                      {...register(`quantity_${comb.id}`)}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div id="select">
            <div className="mb-2 block">
              <Label htmlFor="currency" value="Select Currency" />
            </div>
            <Select id="currency" required={true} {...register("currency")}>
              <option>USD</option>
              <option>UAH</option>
            </Select>
          </div>
          <div id="textarea">
            <div className="mb-2 block">
              <Label htmlFor="description" value="Product Description" />
            </div>
            <Textarea
              id="description"
              placeholder="Describe Product"
              required={true}
              rows={4}
              {...register("description")}
            />
          </div>
          <Button
            className="bg-black text-white rounded-none"
            type="submit"
            // disabled={errors ? true : false}
          >
            Edit product
          </Button>
        </form>
      </LoadingBoundary>
    </div>
  );
};

export default EditProduct;
