import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProductCard from "../ProductList/Product/ProductCard";
import useProductFilter from "./hooks/useProductFilter";

const Catalog = () => {
  const products = useSelector((state) => state.addToCart.products);
  const location = useLocation();
  const initialCategory = location.state
    ? decodeURIComponent(location.hash.split("#")[1])
    : null;

  const {
    products: filteredProducts,
    categories,
    sortOption,
    filterProductsByCategory,
    sortProducts,
    selectedCategory,
  } = useProductFilter(products, initialCategory);

  return (
    <div className="w-full flex justify-center px-5 min-h-default">
      <div className="flex flex-col mt-10 mb-16 pb-8 items-start justify-start w-full max-w-[1200px]">
        <h1 className="text-3xl font-medium px-4">Каталог</h1>
        <div className="w-full flex flex-row sm:justify-between justify-center items-center mt-5 sm:px-4 sm:mt-10">
          <div className="flex flex-row items-center">
            <p className="font-light sm:mr-5 mr-0 text-xs sm:text-base">
              Категорія:
            </p>
            <select
              className="font-light text-xs sm:text-base bg-white border-none sm:mr-5 mr-0 w-[70px] sm:w-[120px] px-1"
              onChange={(e) => filterProductsByCategory(e.target.value)}
              value={selectedCategory}
            >
              <option value="all">Всі</option>
              {categories.map(
                (category) =>
                  category !== "" && (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  )
              )}
            </select>
          </div>
          <div className="flex justify-center items-center">
            <div className="flex flex-row items-center">
              <p className="font-light sm:mr-5 mr-0 text-xs sm:text-base w-[76px] sm:w-[105px]">
                Сортувати за:
              </p>
              <select
                className="font-light text-xs sm:text-base bg-white border-none sm:mr-5 mr-3 w-[120px] sm:w-[180px]"
                onChange={(e) => sortProducts(e.target.value)}
                value={sortOption}
              >
                <option value="name-az">Назвою, А - Я</option>
                <option value="name-za">Назвою, Я - А</option>
              </select>
            </div>
            <p className="font-light text-xs sm:text-base hidden sm:flex">
              {filteredProducts.length === 1
                ? `${products.length} продукт`
                : filteredProducts.length === 2 ||
                  filteredProducts.length === 3 ||
                  filteredProducts.length === 4
                ? `${products.length} продукти`
                : `${products.length} продуктів`}
            </p>
          </div>
        </div>
        <div className="flex justify-center sm:justify-start w-full">
          <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 place-items-center">
            {filteredProducts?.map((el) => {
              return (
                <ProductCard
                  key={el.data.id}
                  product={el.data}
                  documentId={el.docId}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
