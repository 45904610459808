import React from "react";

const PaymentTerms = () => {
  return (
    <div className="py-20 px-10 flex justify-center items-center">
      <div className="flex flex-col max-w-[700px]">
        <h1 className="text-4xl">Оплата та безпека</h1>
        <p className="font-light mt-5">
          Прийняття платежів: DESKWISE пропонує різні зручні способи оплати для
          вашої зручності. Ми приймаємо наступні методи оплати: кредитні та
          дебетові картки (Visa, Mastercard), платіжні системи (Apple Pay,
          Google Pay) та банківські перекази. Виберіть зручний спосіб оплати під
          час оформлення замовлення.
          <br />
          <br />
          Безпека платежів: DESKWISE забезпечує високий рівень безпеки під час
          оплати ваших покупок. Ми використовуємо захищені протоколи передачі
          даних (SSL/TLS) для забезпечення конфіденційності та цілісності вашої
          особистої і фінансової інформації. Ваші платежі обробляються за
          допомогою надійних платіжних шлюзів, що відповідають вимогам
          стандартів безпеки платежної промисловості.
          <br />
          <br />
          Захист особистих даних: DESKWISE дбає про безпеку ваших особистих
          даних. Ми зобов'язуємося захищати вашу приватність та використовувати
          вашу особисту інформацію лише відповідно до нашої політики
          конфіденційності. Ми ніколи не передаємо вашу особисту інформацію
          третім сторонам без вашої згоди, крім випадків, коли це необхідно для
          обробки платежу або дотримання законодавства.
          <br />
          <br />
          Обмеження відповідальності: Ми робимо все можливе, щоб забезпечити
          точність та достовірність інформації, пов'язаної з оплатою та
          безпекою. Проте, DESKWISE не несе відповідальності за будь-які
          помилки, неправильну інформацію або втрату даних, що виникли в
          результаті використання веб-сайту або залежності від наданої
          інформації.
          <br />
          <br />
          Шахрайство та кібербезпека: DESKWISE активно бореться з будь-якими
          спробами шахрайства або кібератак. Ми вживаємо необхідних заходів
          безпеки для захисту наших клієнтів та свого веб-сайту від
          недобросовісних дій. Проте, ми рекомендуємо нашим клієнтам також
          вживати заходів безпеки, таких як збереження конфіденційності свого
          облікового запису та уникають посилання на сумнівні або ненадійні
          джерела.
          <br />
          <br />
          Якщо у вас є будь-які запитання або потреба у додатковій допомозі щодо
          оплати або безпеки, будь ласка, звертайтеся до нашої служби підтримки
          клієнтів. Ми завжди готові допомогти вам.
        </p>
      </div>
    </div>
  );
};

export default PaymentTerms;
