import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { add } from "../../../redux/addToCart/CartSlice";

const useProduct = (id, docId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector((state) => state.addToCart.products);
  const [product, setProduct] = useState(null);
  const [variantList, SetVariantList] = useState(null);
  const [productNumbers, setProductNumbers] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const updateSelectedOption = (variantId, selectedOption) => {
    SetVariantList((prevVariants) =>
      prevVariants.map((variant) =>
        variant.id === variantId
          ? { ...variant, selected: selectedOption }
          : variant
      )
    );
  };

  function checkArraysEquality(array1, array2) {
    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();

    const stringifiedArray1 = JSON.stringify(sortedArray1);
    const stringifiedArray2 = JSON.stringify(sortedArray2);

    return stringifiedArray1 === stringifiedArray2;
  }

  const addToCart = (buyNow) => {
    let ids = "";
    const fillteredVariants = variantList.map((variant, i) => {
      ids += `${variant.selected}${variantList[i + 1] ? "_" : ""}`;
      return {
        title: variant.title,
        selected: variant.selected,
      };
    });
    const cartsProduct = [
      {
        id: `${product.id}_${ids}`,
        name: product.name,
        currency: product.currency,
        image: product.product_images[0],
        price: productNumbers.price,
        all_quantity: productNumbers.quantity,
        quantity: quantity <= 0 || quantity === "" ? 1 : quantity,
        variants: fillteredVariants,
        docId,
        variantId: productNumbers.variantId,
      },
    ];
    dispatch(add(cartsProduct));
    buyNow && navigate("/cart");
    buyNow && window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    if (variantList && product) {
      let options = [];
      variantList.map((data) => {
        options.push(data.selected);
        return data;
      });
      product.variants.map((item) => {
        const areEqual = checkArraysEquality(options, item.variants);
        if (areEqual) {
          setProductNumbers({
            price: item.price,
            oldprice: item.oldprice,
            quantity: item.quantity,
            variantId: item.id,
          });
        }
        return item;
      });
    }
  }, [variantList, product]);

  useEffect(() => {
    if (products.length > 0) {
      const productData = products.filter((product) => product.data.id === id);
      setProduct(productData[0].data);
      SetVariantList(productData[0].data.variantsList);
    }
  }, [products, id]);

  return [
    product,
    variantList,
    quantity,
    setQuantity,
    productNumbers,
    updateSelectedOption,
    addToCart,
  ];
};

export default useProduct;
