import React from "react";

const Variants = ({
  title,
  data,
  variantId,
  updateSelectedOption,
  selected,
}) => {
  return (
    <div className="my-2">
      <p className="text-gray-500 text-sm">{title}</p>
      <div className="flex flex-row flex-wrap w-full">
        {data.map((item, i) => (
          <div
            key={item}
            className={`${
              selected === item
                ? "bg-black rounded-full text-white"
                : "bg-white rounded-full border border-gray-400 text-black"
            } px-5 py-1 cursor-pointer mr-3 mt-1 mb-2 flex justify-center items-center`}
            onClick={() => updateSelectedOption(variantId, item)}
          >
            <p className="">{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Variants;
