import React from "react";
import AddProductForm from "./AddProductForm";

const AddProduct = () => {
  return (
    <div className="flex flex-col items-center justify-center py-10 min-h-[92vh]">
      <h1 className=" font-bold text-3xl mb-10">Add new product</h1>

      <AddProductForm />
    </div>
  );
};

export default AddProduct;
