import { useState } from "react";

const useVariants = () => {
  const [variantsList, SetVariantsList] = useState([
    {
      title: null,
      data: [],
      id: Math.random().toString(36).substring(2),
      selected: null,
    },
  ]);
  const [saved, SetSaved] = useState(false);
  const [variants, SetVariants] = useState([]);

  const addNewVariants = () => {
    SetVariantsList((prev) => [
      ...prev,
      {
        title: null,
        data: [],
        id: Math.random().toString(36).substring(2),
        selected: null,
      },
    ]);
  };

  const deleteVariants = (id) => {
    SetVariantsList((oldValues) => {
      return oldValues.filter((variant) => variant.id !== id);
    });
  };

  const handleNameVariantsChange = (event, id) => {
    const updatedDetails = variantsList.map((variant) => {
      if (variant.id === id) {
        return { ...variant, title: event.target.value };
      }
      return variant;
    });
    SetVariantsList(updatedDetails);
  };

  const handleValueVariantsChange = (itemId, dataIndex, newData) => {
    const updatedVariantsList = variantsList.map((item) => {
      if (item.id === itemId) {
        const updatedData = [...item.data];
        updatedData[dataIndex] = newData;
        return { ...item, data: updatedData, selected: updatedData[0] };
      }
      return item;
    });
    SetVariantsList(updatedVariantsList);
  };

  const addNewOption = (variantId) => {
    const updatedVariantsList = variantsList.map((variant) => {
      if (variant.id === variantId) {
        return { ...variant, data: [...variant.data, ""] };
      }
      return variant;
    });
    SetVariantsList(updatedVariantsList);
  };

  const deleteOption = (variantId, optionName) => {
    const updatedVariantsList = variantsList.map((variant) => {
      if (variant.id === variantId) {
        return {
          ...variant,
          data: variant.data.filter((option) => option !== optionName),
        };
      }
      return variant;
    });
    SetVariantsList(updatedVariantsList);
  };

  function cartesian(...args) {
    var r = [],
      max = args.length - 1;
    function helper(arr, i) {
      for (var j = 0, l = args[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i === max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return r;
  }

  const onSaveVariants = (status) => {
    SetSaved(status);
    if (status) {
      let newVariantsList = [];
      for (let i = 0; i < variantsList.length; i++) {
        newVariantsList.push(variantsList[i].data);
      }
      const combinations = cartesian(...newVariantsList);
      combinations.map((combination) => {
        SetVariants((prev) => [
          ...prev,
          {
            variants: combination,
            price: "",
            oldprice: "",
            quantity: "",
            id: Math.random().toString(36).substring(2),
          },
        ]);
        return combinations;
      });
    } else {
      SetVariants([]);
    }
  };

  const handleChangePrice = (event, id) => {
    const updatedDetails = variants.map((variant) => {
      if (variant.id === id) {
        return { ...variant, price: event.target.value };
      }
      return variant;
    });
    SetVariants(updatedDetails);
  };

  const handleChangeOldPrice = (event, id) => {
    const updatedDetails = variants.map((variant) => {
      if (variant.id === id) {
        return { ...variant, oldprice: event.target.value };
      }
      return variant;
    });
    SetVariants(updatedDetails);
  };
  const handleChangeQuantity = (event, id) => {
    const updatedDetails = variants.map((variant) => {
      if (variant.id === id) {
        return { ...variant, quantity: event.target.value };
      }
      return variant;
    });
    SetVariants(updatedDetails);
  };

  return [
    addNewVariants,
    deleteVariants,
    handleNameVariantsChange,
    handleValueVariantsChange,
    variantsList,
    addNewOption,
    deleteOption,
    saved,
    onSaveVariants,
    variants,
    handleChangePrice,
    handleChangeQuantity,
    handleChangeOldPrice,
  ];
};

export default useVariants;
