import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Skeleton from "../../common/Skeleton";
import useImageLoad from "../../ProductList/hooks/useImageLoad";

const CategoryCard = ({ data }) => {
  const [hoverCard, setHoverCard] = useState(false);
  const navigate = useNavigate();
  const [isLoading, handleImageLoad] = useImageLoad();

  const onRedirect = (link) => {
    const encodedWord = encodeURIComponent(data.category);
    navigate(link + "#" + encodedWord, {
      state: { category: data.category },
      hash: data.category,
    });
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="flex flex-col items-center justify-center cursor-pointer mt-5 relative"
      onClick={() => onRedirect("/catalog")}
      onMouseEnter={() => setHoverCard(true)}
      onMouseLeave={() => setHoverCard(false)}
    >
      <div>
        {isLoading && <Skeleton />}
        <img
          alt="category"
          onLoad={handleImageLoad}
          style={{ display: isLoading ? "none" : "block" }}
          src={data.products[0].product_images[0]}
          className="w-[400px] sm:h-[400px] h-[320px] object-cover bg-gray-200"
        />
      </div>
      <div className="absolute bg-[#ffffffd5] px-5 py-2 flex flex-row justify-center items-center">
        <p className="font-light text-xl text-center mr-5">{data.category}</p>
        <BsArrowRight
          size={20}
          className={hoverCard ? "scale-x-125" : "scale-x-1"}
        />
      </div>
    </div>
  );
};

export default CategoryCard;
