import { createSlice } from "@reduxjs/toolkit";

export const CartSlice = createSlice({
  name: "Cart",
  initialState: {
    cart_products: [],
    sum: 0,
    products: [],
    updateProducts: false,
  },
  reducers: {
    add: (state, { payload }) => {
      if (state.cart_products.some((el) => el.id === payload[0].id)) {
        state.cart_products = state.cart_products.map((el) =>
          el.id === payload[0].id ? { ...el, quantity: el.quantity + 1 } : el
        );
      } else {
        state.cart_products = [...state.cart_products, ...payload];
      }
      state.sum = state.cart_products.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.price * currentValue.quantity,
        0
      );
    },

    updateQuantity: (state, { payload }) => {
      const { variantId, quantity } = payload;
      state.cart_products = state.cart_products.map((item) => {
        if (item.id === variantId) {
          return {
            ...item,
            quantity: quantity,
          };
        }
        return item;
      });
      state.sum = state.cart_products.reduce((accumulator, currentValue) => {
        const quantity =
          currentValue.quantity === "" ? 1 : currentValue.quantity;
        return accumulator + currentValue.price * quantity;
      }, 0);
    },
    extraction: (state, { payload }) => {
      state.cart_products = state.cart_products.filter(
        (el) => el.id !== payload
      );
      state.sum = state.cart_products.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.price * currentValue.quantity,
        0
      );
    },
    clearData: (state) => {
      state.cart_products = [];
      state.sum = 0;
      state.updateProducts = true;
    },
    updateProduct: (state, { payload }) => {
      state.updateProducts = payload;
    },
    setProducts: (state, { payload }) => {
      state.products = payload;
      state.updateProducts = false;
    },
  },
});

export const {
  add,
  extraction,
  updateQuantity,
  clearData,
  setProducts,
  updateProduct,
} = CartSlice.actions;

export default CartSlice.reducer;
