import React from "react";
import { FaTiktok, FaInstagram, FaTelegram } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import visa from "../../assets/visa.svg";
import mc from "../../assets/mc.svg";
import applepay from "../../assets/applepay.svg";
import googlepay from "../../assets/googlepay.svg";

const Footer = () => {
  const navigate = useNavigate();
  const links = useSelector((state) => state.loginSlice.links);

  const redirectTo = (link) => {
    const newTab = window.open(link, "_blank");
    newTab.focus();
  };

  const navigateTo = (link) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="border-t border-b flex flex-col w-full justify-center items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-5 pt-5 max-w-[1200px]">
          <div className="px-10 pt-5">
            <p className="font-medium">Посилання</p>
            <p
              className="font-light my-3 w-max hover:underline  cursor-pointer text-sm"
              onClick={() => navigateTo("/")}
            >
              Головна
            </p>
            <p
              className="font-light my-3 w-max hover:underline  cursor-pointer text-sm"
              onClick={() => navigateTo("/about")}
            >
              Про нас
            </p>
            <p
              className="font-light my-3 w-max hover:underline  cursor-pointer text-sm"
              onClick={() => navigateTo("/faq")}
            >
              FAQ
            </p>
          </div>
          <div className="px-10 pt-5">
            <p className="footer-title">Інформація</p>
            <p
              className="font-light my-3 w-max hover:underline  cursor-pointer text-sm"
              onClick={() => navigateTo("/usage-terms")}
            >
              Умови використання
            </p>
            <p
              className="font-light my-3 w-max hover:underline  cursor-pointer text-sm"
              onClick={() => navigateTo("/delivery-terms")}
            >
              Політика доставки
            </p>
            <p
              className="font-light my-3 w-max hover:underline  cursor-pointer text-sm"
              onClick={() => navigateTo("/privat-terms")}
            >
              Політика приватності
            </p>
            <p
              className="font-light my-3 w-max hover:underline  cursor-pointer text-sm"
              onClick={() => navigateTo("/pay-terms")}
            >
              Оплата та безпека
            </p>
          </div>
          <div className="px-10 pt-5">
            <p className="footer-title">Наша місія</p>
            <p className="font-light my-3 text-sm">
              Якісні матеріали, гарний дизайн, майстерність і довговічність
            </p>
            <div className="flex flex-row ">
              <img src={visa} alt="visa" className="w-[40px] mx-3" />
              <img src={mc} alt="mc" className="w-[40px] mx-3" />
              <img src={applepay} alt="applepay" className="w-[40px] mx-3" />
              <img src={googlepay} alt="googlepay" className="w-[40px] mx-3" />
            </div>
          </div>
        </div>

        <div className="max-w-[1200px]">
          <div className="flex justify-center">
            {links.map((link) => {
              let IconComponent;
              if (link.name === "tiktok") {
                IconComponent = FaTiktok;
              } else if (link.name === "instagram") {
                IconComponent = FaInstagram;
              } else if (link.name === "telegram") {
                IconComponent = FaTelegram;
              }

              return (
                <div key={link.id} className="p-5">
                  <IconComponent
                    size={20}
                    color={"black"}
                    className="cursor-pointer"
                    onClick={() => redirectTo(link.url)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <p className="text-xs text-gray-400 p-5 text-center">
          © 2023, DESKWISE Powered by Domaratskyi
        </p>
      </div>
    </div>
  );
};

export default Footer;
