import React from "react";
import { useSelector } from "react-redux";
import CategoryCard from "./CategoryCard/CategoryCard";

const Category = () => {
  const products = useSelector((state) => state.addToCart.products);

  const filteredProducts = products.reduce((result, product) => {
    // Check if category already exists in the result

    if (!product.data.category || product.data.category.trim() === "") {
      return result; // Skip this product and continue to the next iteration
    }

    const existingCategory = result.find(
      (item) => item.category === product.data.category
    );

    if (existingCategory) {
      // Add the product to the existing category
      existingCategory.products.push(product.data);
    } else {
      // Create a new category entry with the product
      result.push({
        category: product.data.category,
        products: [product.data],
      });
    }

    return result;
  }, []);

  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col mt-10 mb-16 pb-8 items-start w-full justify-center max-w-[1200px] px-5">
        <h1 className="font-light text-4xl">Категорії</h1>
        <div className="w-full grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 place-items-center">
          {filteredProducts &&
            filteredProducts.map((item) => (
              <CategoryCard data={item} key={item.category} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
