import { Carousel } from "flowbite-react";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Acordion from "../common/Acordion";
import CButton from "../common/CButton";
import Quantity from "../common/Quantity";
import Variants from "../common/Variants";
import useProduct from "./hooks/useProduct";

const Product = () => {
  const { id } = useParams();
  let location = useLocation();
  const docId = location.state.docId;

  const [
    product,
    variantList,
    quantity,
    setQuantity,
    productNumbers,
    updateSelectedOption,
    addToCart,
  ] = useProduct(id, docId);

  return (
    <>
      {product && (
        <div className="w-full flex justify-center">
          <div className="max-w-[1200px] w-full pb-20 flex flex-col md:flex-row justify-between items-start">
            <div className="flex md:hidden w-full h-[90vw] rounded-none">
              <Carousel slide={true} className="rounded-div-none rounded-none">
                {product.product_images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt="product-page-img"
                    className="rounded-none h-[90vw] object-cover bg-gray-200"
                  />
                ))}
              </Carousel>
            </div>
            <div className="hidden md:grid md:max-w-[70%] w-full px-5 grid-cols-1 lg:grid-cols-2 gap-1 pt-14">
              {product.product_images.map((image, index) => (
                <div
                  key={index}
                  className={`${
                    index === 0
                      ? "col-span-1 lg:col-span-2 h-[55vw] max-h-[700px]"
                      : "h-[45vw] max-h-[500px] lg:max-h-[350px]"
                  } bg-gray-200 m-2 flex items-center`}
                >
                  <img
                    src={image}
                    className="w-full h-full object-cover"
                    alt="product-page-img-secondary"
                  />
                </div>
              ))}
            </div>
            <div className="min-w-[350px] md:max-w-[370px] w-full px-5 flex flex-col justify-start items-start pt-14 md:sticky top-[96px]">
              <h1 className="text-4xl">{product.name}</h1>
              {productNumbers && (
                <div className="flex flex-row justify-start items-center w-full my-3">
                  {productNumbers.oldprice ? (
                    <h1 className="line-through text-gray-500 text-sm mr-5">
                      {productNumbers.oldprice} {product.currency}
                    </h1>
                  ) : null}
                  <h1
                    className={`${
                      productNumbers.oldprice
                        ? "text-red-500 font-bold text-lg"
                        : ""
                    } `}
                  >
                    {productNumbers.price} {product.currency}
                  </h1>
                  {productNumbers.oldprice && productNumbers.quantity > 0 && (
                    <div className="ml-5 bg-blue-700 rounded-full px-4 text-white flex items-center justify-center ">
                      <p>Розпродаж</p>
                    </div>
                  )}
                  {productNumbers.quantity <= 0 && (
                    <div className="ml-5 bg-gray-500 rounded-full px-4 text-white flex items-center justify-center ">
                      <p>Розпродано</p>
                    </div>
                  )}
                </div>
              )}
              {variantList.map((variant) => (
                <Variants
                  key={variant.id}
                  title={variant.title}
                  updateSelectedOption={updateSelectedOption}
                  data={variant.data}
                  variantId={variant.id}
                  selected={variant.selected}
                />
              ))}
              <Quantity
                quantity={quantity}
                setQuantity={setQuantity}
                avaibleQuantity={productNumbers?.quantity}
              />
              {productNumbers?.quantity > 0 ? (
                <div className="mt-3 w-full">
                  <CButton
                    onClick={() => addToCart(false)}
                    title={"Додати в корзину"}
                    checked
                    styles="text-black border border-black w-full mt-2"
                  />
                  <CButton
                    onClick={() => addToCart(true)}
                    title={"Купити"}
                    styles="text-white border border-black bg-black w-full mt-3"
                  />
                </div>
              ) : (
                <CButton
                  disabled={true}
                  title={"Розпродано"}
                  styles="text-white border border-black bg-black opacity-75 w-full mt-3"
                />
              )}
              <p className="mt-5 text-black opacity-75 font-light leading-7">
                {product.description}
              </p>
              <div className="mt-3"></div>
              {product.details && <Acordion details={product.details} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
