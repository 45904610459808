import React from "react";
import Banner from "../Banner/Banner";
import ProductList from "../ProductList/ProductList";
import { useRef } from "react";
import Category from "../Category/Category";

const LandingPage = () => {
  const productsListRef = useRef(null);
  return (
    <div className=" flex flex-col justify-center items-center relative">
      <Banner productsListRef={productsListRef} />
      <ProductList productsListRef={productsListRef} />
      <Category />
    </div>
  );
};

export default LandingPage;
