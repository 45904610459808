import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { useState } from "react";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const cart_products = useSelector((state) => state.addToCart.cart_products);
  const userInfo = useSelector((state) => state.loginSlice.userInfo);

  const HEADER_LIST = userInfo
    ? [
        { name: "Головна", link: "/" },
        { name: "Каталог", link: "/catalog" },
        { name: "Про нас", link: "/about" },
        { name: "FAQ", link: "/faq" },
        { name: "Замовлення", link: "/orders" },
      ]
    : [
        { name: "Головна", link: "/" },
        { name: "Каталог", link: "/catalog" },
        { name: "Про нас", link: "/about" },
        { name: "FAQ", link: "/faq" },
      ];

  return (
    <div className="flex sticky top-0 left-0 right-0 z-50 justify-center items-center w-full h-20 sm:h-24 px-5 sm:px-12 bg-white border-b">
      <div className="max-w-[1200px] w-full flex justify-between items-center">
        <div
          className="md:hidden flex justify-end items-center"
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          {mobileMenu ? (
            <IoClose size={25} className="hover:cursor-pointer" />
          ) : (
            <RxHamburgerMenu size={25} className="hover:cursor-pointer" />
          )}
        </div>
        <Link
          to="/"
          className="font-semibold tracking-[.15em] text-xl text-black leading-loose sm:text-2xl sm:tracking-[.2em] pt-1"
        >
          DESKWISE
        </Link>
        <div className="gap-x-6 w-8/12 justify-start mx-10 hidden md:flex">
          {HEADER_LIST.map((item) => (
            <Link to={item.link} className="w-[60px]" key={item.link}>
              <div className="flex h-full justify-center items-end hover:underline text-sm text-gray-600 hover:text-black">
                {item.name}
              </div>
            </Link>
          ))}
        </div>
        <div className="flex items-center justify-end md:w-[30%] gap-x-6">
          <Link to="/cart">
            <div className="relative">
              <svg
                className="w-[45px] cursor-pointer hover:scale-110"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="m15.75 11.8h-3.16l-.77 11.6a5 5 0 0 0 4.99 5.34h7.38a5 5 0 0 0 4.99-5.33l-.78-11.61zm0 1h-2.22l-.71 10.67a4 4 0 0 0 3.99 4.27h7.38a4 4 0 0 0 4-4.27l-.72-10.67h-2.22v.63a4.75 4.75 0 1 1 -9.5 0zm8.5 0h-7.5v.63a3.75 3.75 0 1 0 7.5 0z"
                  fill="currentColor"
                  fillRule="evenodd"
                ></path>
              </svg>
              {cart_products.length > 0 && (
                <div className="flex justify-center items-center bg-black rounded-full w-[18px] h-[18px] absolute bottom-1 right-1 text-white text-xs">
                  {cart_products.length > 9 ? "9+" : cart_products.length}
                </div>
              )}
            </div>
          </Link>
          <Link to="/login">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="w-[20px] cursor-pointer hover:scale-110"
              fill="none"
              viewBox="0 0 18 19"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z"
                fill="currentColor"
              ></path>
            </svg>
          </Link>
        </div>
        {mobileMenu && (
          <div className="flex flex-col items-center justify-center absolute top-20 sm:top-24 right-0 left-0 bg-white z-20 py-3">
            {HEADER_LIST.map((item) => (
              <Link
                to={item.link}
                className="py-3 w-full"
                key={item.link}
                onClick={() => setMobileMenu(false)}
              >
                <div className="flex h-full justify-center items-end hover:underline text-sm text-gray-600 hover:text-black">
                  {item.name}
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
