import React from "react";

const Quantity = ({ quantity, setQuantity, disableTitle }) => {
  return (
    <div className="my-2">
      {!disableTitle && <p className="text-gray-500 text-sm">Кількість</p>}
      <div className="flex flex-row justify-between items-center h-[45px] w-[120px] mt-1 border border-black">
        <input
          id="quantity"
          type="number"
          className="border-0 w-full"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Quantity;
