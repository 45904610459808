import { Button } from "flowbite-react";
import React from "react";

import { useNavigate } from "react-router-dom";

const OrderComplete = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-default flex flex-col justify-center items-center my-20">
      <h1 className="text-xl sm:text-3xl">Дякуємо за замовлення 🔥🔥🔥</h1>
      <Button
        onClick={() => navigate("/")}
        className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none mt-10"
      >
        На головну
      </Button>
    </div>
  );
};

export default OrderComplete;
