import { Spinner } from "flowbite-react";

const LoadingBoundary = ({ children, isLoading }) => {
  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute z-50 top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-[#fff]">
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      )}
      {children}
    </div>
  );
};

export default LoadingBoundary;
