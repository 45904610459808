import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { extraction } from "../../redux/addToCart/CartSlice";
import { RiDeleteBin2Line } from "react-icons/ri";
import CButton from "../common/CButton";
import { useNavigate } from "react-router-dom";
import QuantityCart from "../common/QuantityCart";
import Checkout from "../Checkout/Checkout";

const Cart = () => {
  const cart_products = useSelector((state) => state.addToCart.cart_products);
  const sum = useSelector((state) => state.addToCart.sum);
  const min_order_value = useSelector(
    (state) => state.loginSlice.min_order_value
  );
  const [checkout, setCheckout] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = () => {
    setCheckout(true);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {checkout ? (
        <Checkout sum={sum} cart_products={cart_products} />
      ) : (
        <div className="min-h-default flex flex-col justify-center items-center">
          {cart_products.length > 0 ? (
            <div className="w-full flex flex-row justify-center items-center px-5 py-10 sm:px-12">
              <div className="flex flex-col w-full max-w-[1100px]  justify-center items-center">
                <div className="flex flex-row justify-between w-full">
                  <h1 className="text-4xl">Корзина</h1>
                  <div className="flex justify-end items-end">
                    <p
                      className="text-sm border-b border-black cursor-pointer hover:border-b-2  "
                      onClick={() => navigate("/")}
                    >
                      Продовжити покупки
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-1 w-full mt-5 pb-2 border-b border-gray-400">
                  <p className="text-sm text-gray-400 col-span-3 md:col-span-2">
                    Продукт
                  </p>
                  <p className="text-sm text-gray-400 text-center hidden md:block">
                    Кількість
                  </p>
                  <p className="text-sm text-gray-400 text-end">Сума</p>
                </div>
                {cart_products.map((item) => (
                  <div
                    key={item.id}
                    className="grid grid-cols-4 gap-1 w-full mt-10 pb-10 border-b border-gray-400"
                  >
                    <div className="col-span-3 flex flex-col sm:flex-row justify-between">
                      <div className="flex flex-row items-start justify-start ">
                        <img
                          alt="cart-product"
                          src={item.image}
                          className="w-[70px] h-[70px] md:h-[150px] md:w-[150px] object-cover bg-gray-200"
                        />
                        <div className="md:ml-10 ml-5 flex flex-col">
                          <p className="text-sm opacity-80">{item.name}</p>
                          <p className="text-sm opacity-50">
                            {item.price} {item.currency}
                          </p>
                          {item.variants.map((variant, i) => (
                            <p key={i} className="text-sm opacity-50">
                              {variant.title}: {variant.selected}
                            </p>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-row justify-end md:justify-center md:mr-10 mt-5">
                        <div className="h-[50px] flex flex-row items-center">
                          <QuantityCart
                            productQuantity={item.quantity}
                            avaibleQuantity={item.all_quantity}
                            id={item.id}
                            disableTitle={true}
                          />
                          <div className="ml-2">
                            <RiDeleteBin2Line
                              className="cursor-pointer"
                              size={20}
                              onClick={() => dispatch(extraction(item.id))}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-end">
                      <p>
                        {item.quantity === ""
                          ? parseFloat(item.price).toFixed(2)
                          : (parseFloat(item.price) * item.quantity).toFixed(2)}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="w-full mt-10 flex flex-col items-end">
                  <p className="text-xl font-light">
                    Загальна сума {sum} {cart_products[0].currency}
                  </p>
                  <CButton
                    title={"Оформити"}
                    styles="mt-5 text-white bg-black"
                    disabled={sum < min_order_value}
                    onClick={() => {
                      onSubmit();
                    }}
                  />
                  {sum < min_order_value && (
                    <p className="font-light text-sm text-red-500">
                      Мінімальна сума замовлення {min_order_value} UAH
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center h-full">
              <h3 className="text-3xl">Ваша корзина пуста</h3>
              <CButton
                title={"Продовжити покупки"}
                styles="mt-10 text-white bg-black"
                onClick={() => navigate("/")}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;
