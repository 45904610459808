import { useState } from "react";

const useImageLoad = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return [isLoading, handleImageLoad];
};

export default useImageLoad;
