import React from "react";
import { useNavigate } from "react-router-dom";
import ProductCard from "./Product/ProductCard";
import { useSelector } from "react-redux";
import { MdAdd } from "react-icons/md";
import { Button } from "flowbite-react";

const ProductList = ({ productsListRef }) => {
  const countProducts = 8;
  const products = useSelector((state) => state.addToCart.products);

  const userInfo = useSelector((state) => state.loginSlice.userInfo);
  const navigate = useNavigate();

  const onRedirect = (link) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="w-full flex justify-center"
      ref={productsListRef}
      id="products_list"
    >
      <div className="flex w-full flex-col mt-10 mb-16 pb-8 items-center justify-center max-w-[1200px]">
        <div className="flex flex-col w-full justify-start items-center ">
          <p className="text-4xl mb-5 px-5 text-center">
            Сучасний Дизайн. Найкраща Якість.
          </p>
          <p className="font-light px-5 text-center">
            Виготовлені з матеріалів преміум-класу, підвищують продуктивність і
            покращують робочий простір винятковим стилем і функціональністю.
          </p>
        </div>
        <div className="w-full grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-5 mt-5 place-items-center">
          {products?.slice(0, countProducts).map((el) => {
            return (
              <ProductCard
                key={el.data.id}
                product={el.data}
                documentId={el.docId}
              />
            );
          })}
          {userInfo && (
            <div className="flex flex-col items-start justify-start cursor-pointer mt-5 sm:max-w-[270px] max-w-[320px] relative w-full">
              <div
                onClick={() => onRedirect("/add_product")}
                className="flex flex-col items-center justify-center border w-full h-[340px] "
              >
                <MdAdd size={35} color={"#000"} />
                <p className="text-2xl">Додати продукт</p>
              </div>
            </div>
          )}
        </div>
        <Button
          onClick={() => onRedirect("/catalog")}
          className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none mt-10"
        >
          Дивитись всі
        </Button>
      </div>
    </div>
  );
};

export default ProductList;
