import React from "react";

const CButton = ({ title, styles, disabled, onClick, checked }) => {
  return (
    <button
      className={`px-8 relative h-[45px] flex justify-center items-center border ${
        checked && "focus:opacity-60"
      } ${styles} ${disabled && "opacity-50"}`}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default CButton;
