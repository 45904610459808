import { Accordion } from "flowbite-react";
import React from "react";
import { MdOutlineLocalShipping } from "react-icons/md";
import { RxDimensions } from "react-icons/rx";
import { BsCheck2All } from "react-icons/bs";

const Acordion = ({ details }) => {
  return (
    <Accordion flush={true} alwaysOpen={true} className="w-full">
      {details.map((item, i) => (
        <Accordion.Panel key={i}>
          <Accordion.Title>
            <div className="flex flex-row justify-start items-center">
              {item.title.toLowerCase().includes("доставка") ? (
                <MdOutlineLocalShipping size={25} />
              ) : item.title.toLowerCase().includes("розміри") ? (
                <RxDimensions size={25} />
              ) : (
                <BsCheck2All size={25} />
              )}
              <p className="mx-3">{item.title}</p>
            </div>
          </Accordion.Title>
          <Accordion.Content>
            <p className="mb-2 text-gray-500 dark:text-gray-400">{item.data}</p>
          </Accordion.Content>
        </Accordion.Panel>
      ))}
    </Accordion>
  );
};

export default Acordion;
