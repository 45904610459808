import React from "react";
import { FAQInfo } from "../../info";
import { Accordion } from "flowbite-react";

const FAQ = () => {
  return (
    <div className="h-full py-20 px-10 flex justify-center items-center">
      <div className="flex flex-col max-w-[1200px] justify-center items-center">
        <div>
          <div className="flex justify-center mb-10 text-4xl">FAQ</div>
          <Accordion collapseAll={true} alwaysOpen={true} className="max-w-2xl">
            {FAQInfo.map((el) => {
              return (
                <Accordion.Panel key={el.id}>
                  <Accordion.Title>{el.qestion}</Accordion.Title>
                  <Accordion.Content>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      {el.answer}
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
