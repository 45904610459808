import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveUser } from "../../redux/login/loginSlice";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../config/Config";
import { Button } from "flowbite-react";
import CButton from "../common/CButton";

const Login = () => {
  const dispatch = useDispatch();
  const acessUser = useSelector((state) => state.loginSlice.acessUser);
  const userInfo = useSelector((state) => state.loginSlice.userInfo);

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful sign-in
        const user = result.user;
        if (user && user.reloadUserInfo.email === acessUser) {
          dispatch(saveUser(user.reloadUserInfo));
        } else {
          alert("Login failed, use admin email address");
        }
      })
      .catch((error) => {
        // Handle sign-in error
        console.error("Google sign-in error:", error);
      });
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      dispatch(saveUser(null));
      // Optionally perform any additional actions after logout
    } catch (error) {
      // Handle logout error
      console.log(error);
    }
  };

  return (
    <>
      {userInfo ? (
        <div className="min-h-default flex flex-col justify-center items-center">
          <h1 className="text-xl">{userInfo.email}</h1>
          <CButton
            styles={
              "bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none mt-10"
            }
            title={"ВИЙТИ"}
            onClick={() => handleLogout()}
          />
        </div>
      ) : (
        <div className="min-h-default flex flex-col justify-center items-center">
          <h1 className="text-3xl">Вхід в Акаунт </h1>
          <p className="text-sm font-light">Тільки для адміністратора</p>
          <Button
            className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none mt-10"
            onClick={() => handleGoogleSignIn()}
          >
            Авторизуватись
          </Button>
        </div>
      )}
    </>
  );
};

export default Login;
