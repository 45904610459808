import React from "react";

const DeliveryTerms = () => {
  return (
    <div className="py-20 px-10 flex justify-center items-center">
      <div className="flex flex-col max-w-[700px]">
        <h1 className="text-4xl">Політика доставки</h1>
        <p className="font-light mt-5">
          Дякуємо, що обрали DESKWISE. Ми прагнемо забезпечити якісну та швидку
          доставку вашого замовлення. Будь ласка, ознайомтесь з нашою політикою
          доставки для отримання детальної інформації.
          <br />
          <br />
          Види доставки:
          <br />
          <br />
          - Нова Пошта: Ми співпрацюємо з службою доставки Нова Пошта для
          надання швидкої та надійної доставки вашого замовлення. Ви зможете
          вибрати відділення Нової Пошти, до якого буде здійснено доставку.
          <br />
          <br />
          - Укрпошта: Ми також пропонуємо доставку через службу Укрпошта. Ви
          зможете отримати своє замовлення у найближчому відділенні Укрпошти.
          <br />
          <br />
          - Інше: У разі потреби, ми також надаємо можливість самовивозу з
          нашого магазину або доставки на конкретну дату. Будь ласка, зв'яжіться
          з нашою службою підтримки для узгодження деталей.
          <br />
          <br />
          Час відправки замовлення: Ми зазвичай обробляємо та відправляємо
          замовлення протягом 3 днів після його отримання. Цей час не включає
          період доставки, який залежить від обраного вами способу доставки та
          місця призначення.
          <br />
          <br />
          Вартість доставки: Вартість доставки буде розрахована під час
          оформлення замовлення, залежно від вибраного вами способу доставки та
          обсягу замовлення.
          <br />
          <br />
          Якщо у вас є будь-які питання щодо нашої політики доставки або
          потрібна додаткова інформація, будь ласка, зв'яжіться з нашою службою
          підтримки. Ми зробимо все можливе, щоб забезпечити якісну та зручну
          доставку вашого замовлення.
        </p>
      </div>
    </div>
  );
};

export default DeliveryTerms;
