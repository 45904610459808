import { Alert, Button } from "flowbite-react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoadingBoundary from "../common/LoadingBoundary";
import Delivery from "./Delivery/Delivery";
import useCheckout from "./hooks/useCheckout";
import Payment from "./Payment/Payment";
import { HiInformationCircle } from "react-icons/hi";

const Checkout = ({ sum, cart_products }) => {
  const { register, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();

  const [
    isLoading,
    orderStatus,
    error,
    setError,
    onSubmit,
    setActiveTab,
    activeTab,
    newPost,
    handleCity,
    isCitySelected,
    cities,
    warehouses,
    documentBodyRef,
    paymentMethod,
    setPaymentMethod,
    isPayUrlLoading,
    payUrl,
    onClickPayment,
  ] = useCheckout(sum, cart_products, setValue);

  return (
    <LoadingBoundary isLoading={isLoading}>
      {error && (
        <Alert
          color="failure"
          icon={HiInformationCircle}
          onDismiss={() => setError(null)}
        >
          <span>
            <span className="font-medium">{error}</span>
          </span>
        </Alert>
      )}
      {orderStatus === "DELIVERY" ? (
        <Delivery
          register={register}
          handleSubmit={handleSubmit}
          error={error}
          setError={setError}
          onSubmit={onSubmit}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          newPost={newPost}
          handleCity={handleCity}
          isCitySelected={isCitySelected}
          cities={cities}
          warehouses={warehouses}
          documentBodyRef={documentBodyRef}
        />
      ) : orderStatus === "PAY" ? (
        <Payment
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          isPayUrlLoading={isPayUrlLoading}
          payUrl={payUrl}
          onClickPayment={onClickPayment}
        />
      ) : (
        <div className="min-h-default flex flex-col justify-center items-center my-20">
          <h1 className="text-xl sm:text-3xl text-center">
            Дякуємо за замовлення 🔥🔥🔥
          </h1>
          <p className="text-sm text-center mt-5 px-3">
            Незабаром ви зможете відстежувати ваше замовлення в обраному раніше
            поштовому сервісі.
          </p>
          <Button
            onClick={() => navigate("/")}
            className="bg-myblack focus:ring-white hover:bg-myblack text-white rounded-none outline-none mt-10"
          >
            На головну
          </Button>
        </div>
      )}
    </LoadingBoundary>
  );
};

export default Checkout;
