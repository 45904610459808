import React from "react";

const PrivatTerms = () => {
  return (
    <div className="py-20 px-10 flex justify-center items-center">
      <div className="flex flex-col max-w-[700px]">
        <h1 className="text-4xl">Політика приватності</h1>
        <p className="font-light mt-5">
          Дякуємо, що обрали DESKWISE. Ми прагнемо забезпечити захист вашої
          приватності та конфіденційності. Будь ласка, уважно ознайомтесь з
          нашою політикою приватності, щоб дізнатися, як ми збираємо,
          використовуємо, захищаємо та розкриваємо вашу інформацію.
          <br />
          <br />
          Збір і використання інформації: Ми можемо збирати особисту інформацію,
          таку як ваше ім'я, контактна інформація та інші дані, які ви надаєте
          нам при реєстрації або здійсненні покупок на нашому сайті. Ця
          інформація використовується для обробки замовлень, зв'язку з вами та
          надання кращого обслуговування.
          <br />
          <br />
          Конфіденційність і безпека: Ми приділяємо велику увагу захисту вашої
          інформації. Ми вживаємо всіх необхідних заходів для захисту ваших
          персональних даних від несанкціонованого доступу, втрати чи
          зловживання.
          <br />
          <br />
          Використання файлів cookie: Наш сайт може використовувати файли cookie
          для збору деякої інформації про ваші персональні налаштування та
          преференції. Це допомагає нам покращити ваш досвід користувача та
          персоналізувати наш сайт.
          <br />
          <br />
          Розкриття інформації третім особам: Ми не розкриваємо вашу особисту
          інформацію третім особам без вашої згоди, за винятком випадків, коли
          це необхідно для обробки замовлення або згідно з вимогами закону.
          <br />
          <br />
          Зміни в політиці приватності: Ми залишаємо за собою право вносити
          зміни до нашої політики приватності в будь-який час. Будь ласка,
          періодично переглядайте цю політику для ознайомлення з оновленнями.
          <br />
          <br />
          Якщо у вас виникли будь-які питання або потрібна додаткова інформація
          щодо нашої політики приватності, зв'яжіться з нами. Ми цінуємо вашу
          довіру та обіцяємо дотримуватися високих стандартів конфіденційності
          та захисту даних.
        </p>
      </div>
    </div>
  );
};

export default PrivatTerms;
