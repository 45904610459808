import axios from "axios";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "./config/Config";

const entities = [
  {
    type: "bold",
    offset: 6,
    length: 14,
  },
  {
    type: "italic",
    offset: 23,
    length: 15,
  },
];

export const citySearch = async (value) => {
  const data = {
    apiKey: process.env.REACT_APP_NOVA_KEY,
    modelName: "Address",
    calledMethod: "searchSettlements",
    methodProperties: {
      CityName: value,
      Limit: "50",
    },
  };
  const response = await axios.post(
    "https://api.novaposhta.ua/v2.0/json/",
    data
  );
  return response;
};
export const warehouseSearch = async (value) => {
  const data = {
    apiKey: process.env.REACT_APP_NOVA_KEY,
    modelName: "Address",
    calledMethod: "getWarehouses",
    methodProperties: {
      CityRef: value,
    },
  };
  const response = await axios.post(
    "https://api.novaposhta.ua/v2.0/json/",
    data
  );
  return response;
};
export const telegramMessage = async (
  products,
  modalData,
  sum,
  setIsLoading,
  setOrderCompleted,
  setError,
  paymentMethod,
  payUrl,
  dispatch,
  clearData,
  saveOrderInDB
) => {
  let messageText = `
  ______________________________

  👇🏼👇🏼👇🏼 ЗАМОВЛЕННЯ 👇🏼👇🏼👇🏼
  `;

  products.map((item) => {
    updateQuantityAfterOrderCompleted(
      item.docId,
      item.variantId,
      item.quantity
    );
    messageText += `
<b>Назва:</b> ${item.name}
<b>Фото:</b> <a href="${item.image}">Фото продукта</a>
<b>Кількість:</b> ${item.quantity}
${item.variants.map((obj) => `<b>${obj.title}:</b> ${obj.selected}`).join("\n")}

`;
    return messageText;
  });

  messageText += `
<b>Загальна сумма:</b> ${sum}
<b>Оплата:</b> ${
    paymentMethod === "AFTER_PAYMENT" ? "НЕ ОПЛАЧЕНО!!!!" : "ОПЛАЧЕНА!!!"
  }

<b>Пошта:</b> ${modalData.ShippingService}
<b>Місто:</b> <pre style="font-style:monospace"> ${modalData.City}</pre>
<b>Відділення:</b> <pre style="font-style:monospace">${modalData.NewPost}</pre>
<b>Імя:</b> <pre style="font-style:monospace">${modalData.Name}</pre>
<b>Телефон:</b> ${modalData.Phone}
`;
  const message = {
    chat_id: process.env.REACT_APP_CHAT_ID,
    text: messageText,
    parse_mode: "HTML",
    entities: entities,
  };

  if (paymentMethod === "AFTER_PAYMENT") {
    axios
      .post(
        `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_TOKEN}/sendMessage`,
        message
      )
      .then(async () => {
        console.log("Message sent successfully");
        await saveOrderInDB(products, sum, paymentMethod, modalData);
        dispatch(clearData());
        setOrderCompleted(true);
        window.scrollTo(0, 0);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        setError("Something went wrong. ⛈️ Try again or try later.");
        setIsLoading(false);
      });
  } else if (paymentMethod) {
    await saveOrderInDB(products, sum, paymentMethod, modalData);
    dispatch(clearData());
    window.location.href = payUrl;
    setIsLoading(false);
  }
};

export const updateQuantityAfterOrderCompleted = (
  docId,
  variantId,
  newQuantity
) => {
  const docRef = doc(db, "Products", docId);
  getDoc(docRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        // Step 2: Access the array within the document
        const data = docSnapshot.data();
        const objectsArray = data.variants;

        // Step 3: Locate the specific object within the array
        const objectToUpdate = objectsArray.find((obj) => obj.id === variantId);

        if (objectToUpdate) {
          // Step 4: Update the desired field
          objectToUpdate.quantity =
            parseInt(objectToUpdate.quantity) - parseInt(newQuantity); // Set the new quantity value

          // Step 5: Save the modified document back to Firebase
          updateDoc(docRef, { variants: objectsArray })
            .then(() => {
              console.log("Document successfully updated!");
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        } else {
          console.log("Object not found in the array.");
        }
      } else {
        console.log("Document does not exist.");
      }
    })
    .catch((error) => {
      console.error("Error getting document: ", error);
    });
};

export const updateOrderStatus = (docId, payStatus) => {
  const docRef = doc(db, "Orders", docId);
  updateDoc(docRef, { payStatus })
    .then(() => {
      console.log("Document successfully updated!");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

export const saveOrderInDB = async (
  products,
  sum,
  paymentMethod,
  modalData
) => {
  const now = new Date();
  const timestamp = Timestamp.fromDate(now);

  await addDoc(collection(db, "Orders"), {
    id: Math.random().toString(36).substring(2).toString(),
    paymentMethod,
    payStatus: paymentMethod === "AFTER_PAYMENT" ? "cash" : "created",
    products,
    generalSum: sum,
    city: modalData.City,
    name: modalData.Name,
    phone: modalData.Phone,
    shipping: modalData.ShippingService,
    shippingAddress: modalData.NewPost,
    date: timestamp,
  });
};

export const createPayment = (
  cart_products,
  sum,
  setIsPayUrlLoading,
  setPayUrl,
  setInvoiceID,
  setError
) => {
  let reference = cart_products.reduce(
    (reference, current) => reference + current.id,
    ""
  );

  const basketOrder = cart_products.map((item, index) => {
    return {
      name: item.name,
      qty: parseFloat(item.quantity),
      sum: parseFloat(item.price) * 100,
      icon: item.image,
      unit: "шт.",
      code: item.id,
    };
  });

  const url = `${window.location.protocol}//${window.location.host}`;

  const body = {
    amount: sum * 100,
    ccy: 980,
    merchantPaymInfo: {
      reference: reference,
      destination: "Оплата товару",
      basketOrder: basketOrder,
    },
    redirectUrl: `${url}/order-complete`,
    webHookUrl: "https://deskwise-backend.onrender.com/webhook/",
    validity: 1000,
    paymentType: "debit",
    saveCardData: {
      saveCard: true,
    },
  };

  const headers = {
    "Content-Type": "application/json",
    "X-Token": process.env.REACT_APP_MONO_TOKEN,
  };

  axios
    .post(`https://api.monobank.ua/api/merchant/invoice/create`, body, {
      headers: headers,
    })
    .then((response) => {
      setInvoiceID(response.data.invoiceId);
      setPayUrl(response.data.pageUrl);
      setIsPayUrlLoading(false);
    })
    .catch((error) => {
      console.error("Error sending message:", error);
      setError("Something went wrong. ⛈️ Try again or try later.");
      setIsPayUrlLoading(false);
    });
};

export const getPaymentStatus = async (
  invoiceId,
  setError,
  setLoading,
  setPayStatus,
  docId
) => {
  const headers = {
    "Content-Type": "application/json",
    "X-Token": process.env.REACT_APP_MONO_TOKEN,
  };

  axios
    .get(
      `https://api.monobank.ua/api/merchant/invoice/status?invoiceId=${invoiceId}`,
      {
        headers: headers,
      }
    )
    .then((response) => {
      setPayStatus(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error sending message:", error);
      setError("Something went wrong. ⛈️ Try again or try later.");
      setLoading(false);
    });
};

// export const deletePaymentInvoice = async (
//   invoiceId,
//   setError,
//   setLoading,
//   setPayUrl
// ) => {
//   const headers = {
//     "Content-Type": "application/json",
//     "X-Token": process.env.REACT_APP_MONO_TOKEN,
//   };
//   const body = {
//     invoiceId: invoiceId,
//   };

//   axios
//     .post(`https://api.monobank.ua/api/merchant/invoice/remove`, body, {
//       headers: headers,
//     })
//     .then((response) => {
//       setLoading(false);
//       setPayUrl(null);
//     })
//     .catch((error) => {
//       console.error("Error sending message:", error);
//       setError("Something went wrong. ⛈️ Try again or try later.");
//       setLoading(false);
//     });
// };

// export const paymentDetailsMessage = async (payStatus) => {
//   let messageText = `
//   ______________________________

//   👇🏼👇🏼👇🏼 ОПЛАТА 👇🏼👇🏼👇🏼
//   `;

//   messageText += `
// <b>InvoiceId:</b> ${payStatus.invoiceId}
// <b>Status:</b> ${payStatus.status}

// ${
//   payStatus?.failureReason
//     ? `<b>FailureReason:</b> <pre style="font-style:monospace">${payStatus?.failureReason}</pre>`
//     : ""
// }
// <b>Amount:</b> ${payStatus.amount / 100}
// `;
//   const message = {
//     chat_id: process.env.REACT_APP_CHAT_ID,
//     text: messageText,
//     parse_mode: "HTML",
//     entities: entities,
//   };

//   axios
//     .post(
//       `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_TOKEN}/sendMessage`,
//       message
//     )
//     .then((response) => {
//       console.log("Message sent successfully");
//     })
//     .catch((error) => {
//       console.error("Error sending message:", error);
//     });
// };
