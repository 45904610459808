import { Label, TextInput } from "flowbite-react";
import React from "react";

const UkrPoshta = ({ register }) => {
  return (
    <div>
      <div>
        <div className="mb-2">
          <Label value="Місто" />
        </div>
        <TextInput
          type="text"
          {...register("city", {
            required: true,
          })}
        />
      </div>
      <div className="mt-5">
        <div className="mb-2">
          <Label value={"Індекс відділення"} />
        </div>
        <TextInput
          type="text"
          {...register("index", {
            required: true,
          })}
        />
      </div>
    </div>
  );
};

export default UkrPoshta;
