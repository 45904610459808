import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header/Header";
import LandingPage from "./components/LandingPage/LandingPage";
import Shop from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import AddProduct from "./components/AddProduct/AddProduct";
import Product from "./components/Product/Product";
import Cart from "./components/Cart/Cart";
import EditProduct from "./components/EditProduct/EditProduct";
import Login from "./components/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./config/Config";
import { saveUser } from "./redux/login/loginSlice";
import { setProducts } from "./redux/addToCart/CartSlice";
import { collection, getDocs } from "firebase/firestore";
import LoadingBoundary from "./components/common/LoadingBoundary";
import Catalog from "./components/Catalog/Catalog";
import About from "./components/About/About";
import UsageTerms from "./components/Terms/UsageTerms";
import DeliveryTerms from "./components/Terms/DeliveryTerms";
import PrivatTerms from "./components/Terms/PrivatTerms";
import OrderComplete from "./components/Checkout/OrderComplete/OrderComplete";
import Orders from "./components/Orders/Orders";
import PaymentTerms from "./components/Terms/PaymentTerm";

function App() {
  const userInfo = useSelector((state) => state.loginSlice.userInfo);
  const dispatch = useDispatch();
  const acessUser = useSelector((state) => state.loginSlice.acessUser);
  const updateProducts = useSelector((state) => state.addToCart.updateProducts);

  const [isLoading, setIsLoading] = useState(false);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const querySnapshot = await getDocs(collection(db, "Products"));
      const productsData = querySnapshot.docs.map((doc) => {
        return { data: doc.data(), docId: doc.id };
      });
      dispatch(setProducts(productsData));
      setIsLoading(false);
    } catch (error) {
      console.error("Error getting products:", error);
      dispatch(setProducts([]));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        if (user && user.reloadUserInfo.email === acessUser) {
          dispatch(saveUser(user.reloadUserInfo));
        } else {
          alert("Login failed, use admin email address");
        }
      } else {
        // User is signed out
        console.log("User is signed out");
      }
    });
    unsubscribe();
    fetchProducts();
  }, []);

  useEffect(() => {
    if (updateProducts) {
      fetchProducts();
    }
    // Clean up the subscription when the component unmounts
  }, [updateProducts]);

  return (
    <LoadingBoundary isLoading={isLoading}>
      <div>
        <Header />
        {userInfo ? (
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/faq" element={<Shop />} />
            <Route path="/usage-terms" element={<UsageTerms />} />
            <Route path="/pay-terms" element={<PaymentTerms />} />
            <Route path="/delivery-terms" element={<DeliveryTerms />} />
            <Route path="/privat-terms" element={<PrivatTerms />} />
            <Route path="/about" element={<About />} />
            <Route path="/add_product" element={<AddProduct />} />
            <Route path="/edit_product" element={<EditProduct />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/order-complete" element={<OrderComplete />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Shop />} />
            <Route path="/usage-terms" element={<UsageTerms />} />
            <Route path="/pay-terms" element={<PaymentTerms />} />
            <Route path="/delivery-terms" element={<DeliveryTerms />} />
            <Route path="/privat-terms" element={<PrivatTerms />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/order-complete" element={<OrderComplete />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
        <Footer />
      </div>
    </LoadingBoundary>
  );
}

export default App;
