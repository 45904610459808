import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../../../config/Config";
import { updateProduct } from "../../../redux/addToCart/CartSlice";

const useEditProduct = (product, setValue, docId) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setValue("name", product.name);
    setValue("description", product.description);
    setValue("currency", product.currency);

    product.variants.map((variant) => {
      setValue(`price_${variant.id}`, variant.price);
      setValue(`oldprice_${variant.id}`, variant.oldprice);
      setValue(`quantity_${variant.id}`, variant.quantity);
      return variant;
    });
  }, [product, setValue]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const variants = product.variants.map((variant) => {
      return {
        ...variant,
        price: data[`price_${variant.id}`],
        oldprice: data[`oldprice_${variant.id}`],
        quantity: data[`quantity_${variant.id}`],
      };
    });
    const docRef = doc(db, "Products", docId);
    try {
      await updateDoc(docRef, {
        name: data.name,
        variants,
        currency: data.currency,
        description: data.description,
      });
      console.log("Document updated successfully.");
      dispatch(updateProduct(true));
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      console.error("Error updating document:", error);
      setIsLoading(false);
    }
  };

  return [onSubmit, isLoading];
};

export default useEditProduct;
