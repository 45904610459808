import { useEffect, useState } from "react";

const useProductFilter = (initialProducts, initialCategory) => {
  const [products, setProducts] = useState(initialProducts);
  const [categories, setCategories] = useState([]);
  const [sortOption, setSortOption] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");

  useEffect(() => {
    const uniqueCategories = [
      ...new Set(
        initialProducts.map((product) => {
          return product.data.category;
        })
      ),
    ];
    let sortedCategories = [...uniqueCategories];
    sortedCategories.sort((a, b) => a.localeCompare(b));
    setCategories(sortedCategories);
    if (initialCategory) {
      const filteredProducts = initialProducts.filter(
        (product) => product.data.category === initialCategory
      );
      setSelectedCategory(initialCategory);
      setProducts(filteredProducts);
    } else {
      setProducts(initialProducts);
    }
  }, [initialProducts, initialCategory]);

  const sortProducts = (value) => {
    let sortedProducts = [...products];
    setSortOption(value);
    if (value === "name-az") {
      sortedProducts.sort((a, b) => a.data.name.localeCompare(b.name));
    } else if (value === "name-za") {
      sortedProducts.sort((a, b) => b.data.name.localeCompare(a.name));
    }

    setProducts(sortedProducts);
  };

  // Filter products based on the selected category
  const filterProductsByCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
    if (selectedCategory === "all") {
      setProducts(initialProducts);
    } else {
      const filteredProducts = initialProducts.filter(
        (product) => product.data.category === selectedCategory
      );
      setProducts(filteredProducts);
    }
  };

  return {
    products,
    categories,
    sortOption,
    filterProductsByCategory,
    sortProducts,
    selectedCategory,
  };
};

export default useProductFilter;
