import React from "react";
import { useDispatch } from "react-redux";
import { updateQuantity } from "../../redux/addToCart/CartSlice";

const QuantityCart = ({
  productQuantity,
  avaibleQuantity,
  disableTitle,
  id,
}) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(
      updateQuantity({
        variantId: id,
        quantity: e.target.value,
      })
    );
  };

  return (
    <div className="my-2 relative">
      {!disableTitle && <p className="text-gray-500 text-sm">Кількість</p>}
      <div className="flex flex-row justify-between items-center h-[45px] w-[120px] mt-1 border border-black">
        <input
          id="quantity"
          type="number"
          className="border-0 w-full"
          value={productQuantity}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <p className="font-light text-gray-500 text-xs absolute">
        Доступно {avaibleQuantity}шт.
      </p>
    </div>
  );
};

export default QuantityCart;
