import React from "react";

const UsageTerms = () => {
  return (
    <div className="py-20 px-10 flex justify-center items-center">
      <div className="flex flex-col max-w-[700px]">
        <h1 className="text-4xl">Умови використання</h1>
        <p className="font-light mt-5">
          Ласкаво просимо на сайт DESKWISE. Будь ласка, уважно ознайомтесь з
          нашими умовами використання перед використанням нашого сайту.
          <br />
          <br />
          Прийняття умов: Використовуючи наш сайт, ви погоджуєтесь з усіма
          умовами, зазначеними тут. Якщо ви не погоджуєтесь з умовами, будь
          ласка, не використовуйте наш сайт.
          <br />
          <br />
          Інтелектуальна власність: Всі матеріали та контент, розміщені на
          нашому сайті, включаючи текст, зображення, логотипи та інші елементи,
          є власністю DESKWISE або наших ліцензіарів. Ви не маєте права
          копіювати, розповсюджувати або використовувати ці матеріали без нашої
          письмової згоди.
          <br />
          <br />
          Користування сайтом: Ви можете використовувати наш сайт лише
          відповідно до встановлених нами правил і обмежень. Ви не маєте права
          виконувати будь-які дії, які можуть завдати шкоди сайту або
          перешкодити його нормальному функціонуванню.
          <br />
          <br />
          Користувацькі облікові записи: Деякі функції нашого сайту можуть бути
          доступні лише для зареєстрованих користувачів. При створенні
          облікового запису ви зобов'язуєтесь надати правдиву та точну
          інформацію. Ви не маєте права передавати свій обліковий запис третім
          особам або використовувати обліковий запис іншої особи без їх згоди.
          <br />
          <br />
          Конфіденційність: Ми поважаємо вашу конфіденційність і зобов'язуємося
          захищати ваші персональні дані від несанкціонованого доступу або
          використання. Будь ласка, ознайомтеся з нашою політикою
          конфіденційності, щоб детальніше дізнатися про те, як ми збираємо,
          використовуємо та захищаємо вашу інформацію.
          <br />
          <br />
          Посилання на сторонні ресурси: Наш сайт може містити посилання на
          сторонні веб-сайти або ресурси, які не контролюються нами. Ми не
          несемо відповідальності за зміст, політику конфіденційності або
          практики таких сторонніх ресурсів.
          <br />
          <br />
          Зміни умов: Ми залишаємо за собою право вносити зміни до умов
          використання в будь-який час. Будь ласка, періодично переглядайте ці
          умови для ознайомлення з оновленнями.
          <br />
          <br />
          Якщо у вас є будь-які питання щодо наших умов використання, будь
          ласка, зв'яжіться з нами. Дякуємо, що вибрали DESKWISE - ваше місце
          призначення для якісних килимків для миші.
        </p>
      </div>
    </div>
  );
};

export default UsageTerms;
