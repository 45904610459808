import { Label, Select, TextInput } from "flowbite-react";
import React from "react";

const Poshta = ({
  register,
  onChangeCity,
  handleCity,
  isCitySelected,
  cities,
  warehouses,
  seconLabel,
}) => {
  return (
    <>
      <div>
        <div className="mb-2">
          <Label value="Місто" />
        </div>
        <TextInput
          type="text"
          {...register("city", {
            onChange: (e) => onChangeCity(e.target.value),
            required: true,
          })}
        />
        {isCitySelected ? null : (
          <div className="relative max-w-[340px]">
            <div className="absolute z-50 mt-1 w-full max-h-44 rounded shadow overflow-y-auto bg-white">
              {cities?.map((city) => {
                return (
                  <div
                    onClick={() => handleCity(city.Present, city.DeliveryCity)}
                    key={city.Ref}
                    className="hover:bg-gray-100 py-1 px-2 cursor-pointer"
                  >
                    {city.Present}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="mt-5">
        <div className="mb-2">
          <Label value={seconLabel} />
        </div>
        <Select {...register("new_post", { required: true })}>
          {warehouses?.map((warehouse) => (
            <option className="max-w-[340px]" key={warehouse.Ref}>
              {warehouse.Description}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default Poshta;
