import React from "react";
import hero_bg from "../../assets/hero_bg.png";
import CButton from "../common/CButton";

const Banner = ({ productsListRef }) => {
  const scrollToProductsList = () => {
    if (productsListRef.current) {
      productsListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className=" flex items-center justify-center w-full max-h-[800px] ">
      <div className=" flex items-center justify-center relative w-full min-h-[400px]">
        <img
          className="w-full object-cover min-h-[400px] max-h-[800px]"
          src={hero_bg}
          alt=""
        />
        <div className="flex flex-col justify-center items-center absolute mt-10 w-full">
          <h1 className="text-center text-white text-2xl xl:text-5xl sm:text-3xl max-w-[90%] sm:max-w-[50%]">
            Відкрийте для себе преміальні килимки для мишки.
          </h1>
          <CButton
            title={"Замовити"}
            onClick={scrollToProductsList}
            styles="mt-10 text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
