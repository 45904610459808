import React from "react";

const PayStatus = ({ activeOrder }) => {
  return (
    <div className="flex flex-row justify-start items-center">
      <div
        className={`${
          activeOrder.payStatus === "created" ||
          activeOrder.payStatus === "processing" ||
          activeOrder.payStatus === "hold" ||
          activeOrder.payStatus === "reversed"
            ? "bg-yellow-300"
            : activeOrder.payStatus === "failure" ||
              activeOrder.payStatus === "expired"
            ? "bg-red-600"
            : activeOrder.payStatus === "success"
            ? "bg-green-500"
            : "bg-black"
        } w-[10px] h-[10px] rounded-full`}
      />
      <p className="text-sm pl-1">{activeOrder.payStatus}</p>
    </div>
  );
};

export default PayStatus;
