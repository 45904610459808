export const productInfo = [
  {
    name: "Сенсор Abbott FreeStyle Libre 2",
    price: 3399.0,
    sale: "10%",
    id: 10,
  },
  {
    name: "Трансмітер Dexcom G6",
    price: 5999.0,
    sale: "10%",
    id: 11,
  },
  {
    name: "Приймач Dexcom G6",
    price: 15999.0,
    sale: "10%",
    id: 12,
  },
  {
    name: "Сенсор Dexcom G6",
    price: 3299.0,
    sale: "10%",
    id: 13,
  },
  {
    name: "Сенсор Dexcom G6",
    price: 3299.0,
    sale: "10%",
    id: 16,
  },
  {
    name: "Сенсор Dexcom G6",
    price: 3299.0,
    sale: "10%",
    id: 14,
  },
  {
    name: "Сенсор Dexcom G6",
    price: 3299.0,
    sale: "10%",
    id: 15,
  },
  {
    name: "Сенсор Abbott FreeStyle Libre 2",
    price: 3399.0,
    sale: "10%",
    id: 19,
  },
  {
    name: "Сенсор Abbott FreeStyle Libre 2",
    price: 3399.0,
    sale: "10%",
    id: 21,
  },
  {
    name: "Сенсор Abbott FreeStyle Libre 2",
    price: 3399.0,
    sale: "10%",
    id: 22,
  },
  {
    name: "Сенсор Abbott FreeStyle Libre 2",
    price: 3399.0,
    sale: "10%",
    id: 23,
  },
];

export const FAQInfo = [
  {
    qestion: "Чи можна прати килимки для робочих поверхонь і мишок?",
    answer:
      "Так, наші килимки для робочих поверхонь і мишок можна прати. Більшість наших килимків можна прати вручну або в пральній машині за допомогою ніжних циклів та м'якого миючого засобу. Детальні інструкції з прання надаються разом з килимком.",
    id: 1,
  },
  {
    qestion: "Які матеріали використовуються для виготовлення килимків?",
    answer:
      "Наші килимки виготовляються з високоякісних матеріалів, таких як натуральний каучук, високоякісна тканина та мікроволокно. Ми використовуємо екологічно чисті матеріали, що не містять шкідливих речовин.",
    id: 2,
  },
  {
    qestion:
      "Які переваги використання килимків для робочих поверхонь і мишок?",
    answer:
      "Забезпечують комфорт при роботі за комп'ютером, допомагаючи знизити втому рук і зап'ястків. \n Забезпечують гладке ковзання мишки, що полегшує точне переміщення курсору. \n Захищають поверхню робочого столу від подряпин та інших пошкоджень. \n Мають стильний дизайн і додають естетичну привабливість до вашого робочого простору.",
    id: 3,
  },
  {
    qestion: "Як можна замовити килимок?",
    answer:
      "Замовити килимок на нашому сайті - це просто! Просто перейдіть на сторінку каталогу, оберіть бажаний дизайн або розмір, додайте його в кошик і оформіть замовлення. Ми приймаємо різні способи оплати і надаємо доставку до вашої адреси.",
    id: 4,
  },
  {
    qestion:
      "Чи можна повернути або обміняти килимок, якщо він не задовольняє мої вимоги?",
    answer:
      "Так, ми приймаємо повернення і здійснюємо обмін килимків, якщо вони не задовольняють ваші вимоги. Будь ласка, ознайомтеся з нашою політикою повернення та обміну на нашому сайті або зв'яжіться з нашою командою підтримки, щоб отримати додаткову інформацію та керівництво щодо процедури повернення.",
    id: 5,
  },
  {
    qestion: "Чи надаєте ви оптові знижки для бізнес-замовлень?",
    answer:
      "Так, ми надаємо спеціальні оптові знижки для бізнес-замовлень. Якщо ви зацікавлені в оптових замовленнях для вашої компанії або організації, будь ласка, зв'яжіться з нашою командою продажів, і ми з радістю обговоримо ваші потреби та запропонуємо вам вигідні умови.",
    id: 6,
  },
];
