import { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  citySearch,
  createPayment,
  saveOrderInDB,
  telegramMessage,
  warehouseSearch,
} from "../../../api";
import { clearData } from "../../../redux/addToCart/CartSlice";

const useCheckout = (sum, cart_products, setValue) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const documentBodyRef = useRef(null);
  const [warehouses, setWarehouses] = useState([]);
  const [cities, setCities] = useState([]);
  const [isCitySelected, setIsCitySelected] = useState(false);
  const [orderStatus, setOrderStatus] = useState("DELIVERY");
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("Offline");
  const [isPayUrlLoading, setIsPayUrlLoading] = useState(false);
  const [payUrl, setPayUrl] = useState(null);
  const [invoiceID, setInvoiceID] = useState(localStorage.getItem("invoiceId"));

  useEffect(() => {
    warehouses.length > 0 && setValue("new_post", warehouses[0].Description);
  }, [warehouses, setValue]);

  useEffect(() => {
    documentBodyRef.current = document.body;
  }, []);

  useEffect(() => {
    if (paymentMethod === "Online" && !invoiceID) {
      createPayment(
        cart_products,
        sum,
        setIsPayUrlLoading,
        setPayUrl,
        setInvoiceID,
        setError
      );
    }
  }, [paymentMethod, cart_products, invoiceID, sum]);

  const newPost = async (value) => {
    setIsCitySelected(false);
    setWarehouses([]);
    setCities([]);
    const response = await citySearch(value);
    setCities(response.data.data[0].Addresses);
  };

  const handleCity = async (cityName, ref) => {
    setValue("city", cityName);
    setIsCitySelected(true);
    const response = await warehouseSearch(ref);
    setWarehouses(response.data.data);
  };

  const onSubmit = (data) => {
    setModalData({
      Name: data.full_name,
      Phone: data.phone,
      ShippingService:
        activeTab === 0 ? "Нова пошта" : activeTab === 1 ? "Укрпошта" : "Інше",
      City: activeTab === 2 ? null : data.city,
      NewPost:
        activeTab === 0 ? data.new_post : activeTab === 1 ? data.index : null,
    });
    setOrderStatus("PAY");
    window.scrollTo(0, 0);
  };

  const onClickPayment = () => {
    setIsLoading(true);
    telegramMessage(
      cart_products,
      modalData,
      sum,
      setIsLoading,
      setOrderStatus,
      setError,
      paymentMethod === "Online" ? invoiceID : "AFTER_PAYMENT",
      payUrl ? payUrl : null,
      dispatch,
      clearData,
      saveOrderInDB
    );
  };

  return [
    isLoading,
    orderStatus,
    error,
    setError,
    onSubmit,
    setActiveTab,
    activeTab,
    newPost,
    handleCity,
    isCitySelected,
    cities,
    warehouses,
    documentBodyRef,
    paymentMethod,
    setPaymentMethod,
    isPayUrlLoading,
    payUrl,
    onClickPayment,
  ];
};

export default useCheckout;
